import React from 'react';
import Header from '../header';
import ServicesComponent from './service-components';
import useDocumentTitle from './use_documents';
import SEO from '../seo';

const Services = () => {
  useDocumentTitle("Impact Minds - Services");

  const heading = {
    primary: '',
    secondary: 'Our Services',
    tertiary: '',
  };

  const description = `At Impact Minds, we have assembled a team of top-tier experts and developers across multiple disciplines, offering cutting-edge solutions in mobile and web development, AI, blockchain, and more. Our seasoned professionals deliver excellence in every project.`;

  return (
    <section id="services">
      <SEO
        title="Impact Minds - Our Services"
        description="Explore the range of IT services offered by Impact Minds, including software development, web design, digital marketing, and more."
        keywords="IT services, software development, web design, digital marketing"
        url="https://impactmindstech.com/services"
        image="%PUBLIC_URL%/logo192.png"
      />
      <Header description={description} heading={heading} />
      <ServicesComponent />
    </section>
  );
};

export default Services;
