import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import githubIcon from './images/github.svg';
import linkedinIcon from './images/linkdin.svg';
import instagramIcon from './images/instagram.svg';
import phoneIcon from './images/phone.svg';
import locationIcon from './images/location.svg';
import emailIcon from './images/email.svg';
import logo from './logo1.png';
import SEO from './seo';
import './css/footer.css'; // Your CSS file for styling

const Footer = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [checkScrollTop]);

  const seoData = {
    title: "Impact Minds",
    description: "Impact Minds provides top-notch IT services including mobile development, web development, SEO, and marketing. Contact us today to boost your business.",
    keywords: ["IT services", "mobile development", "web development", "SEO", "marketing", "iOS development", "Android development", "software solutions", "tech company", "Impact Minds"],
    url: "https://impactmindstech.com/",
    image: "%PUBLIC_URL%/logo192.png"
  };

  return (
    <>
      <SEO {...seoData} />
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logo} alt="Company Logo" height="170" />
          </div>

          <div className="footer-content">
            <div className="section">
              <h3>Location</h3>
              <p className="contact-info">
                <img src={locationIcon} alt="Location" />
                <a href="https://www.google.com/maps/place/Kohinoor+Plaza+I/@31.4126038,73.1151958,17z/data=!3m1!4b1!4m6!3m5!1s0x392268725b330195:0x41252daea86b04b7!8m2!3d31.4126038!4d73.1151958!16zL20vMGZmcHM0?entry=ttu" target="_blank" rel="noopener noreferrer">
                  Kohinoor Plaza, Faisalabad
                </a>
              </p>
              <p className="contact-info">
                <img src={phoneIcon} alt="Phone" />
                <a href="tel:+923276228976">
                  +92 327 6228976
                </a>
              </p>
              <p className="contact-info">
                <img src={emailIcon} alt="Email" />
                <a href="mailto:impact.minds0@gmail.com">
                  impact.minds0@gmail.com
                </a>
              </p>
            </div>

            <div className="section">
              <h3>What We Are</h3>
              <p><NavLink to="/about" onClick={scrollTop}>About</NavLink></p>
              <p><NavLink to="/services" onClick={scrollTop}>Services</NavLink></p>
              <p><NavLink to="/portfolio" onClick={scrollTop}>Portfolio</NavLink></p>
            </div>

            <div className="section">
              <h3>Services</h3>
              <p>Mobile Development</p>
              <p>Web Development</p>
              <p>iOS Development</p>
              <p>Android Development</p>
              <p>SEO</p>
              <p>Marketing</p>
            </div>

            <div className="section">
              <h3>Follow Us On</h3>
              <div className="social-icons">
                <a href="https://github.com/impactminds" target="_blank" rel="noopener noreferrer" className="icon github">
                  <img src={githubIcon} alt="GitHub" />
                </a>
                <a href="https://www.linkedin.com/company/impactminds0/" target="_blank" rel="noopener noreferrer" className="icon linkedin">
                  <img src={linkedinIcon} alt="LinkedIn" />
                </a>
                <a href="https://www.instagram.com/impact.minds" target="_blank" rel="noopener noreferrer" className="icon instagram">
                  <img src={instagramIcon} alt="Instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;