// src/components/ServicesComponent.js
import React, { useEffect, useRef } from 'react';
import CustomCard from '../custom-card';
import phoneIcon from '../images/mobilephone.svg';
import webIcon from '../images/web.svg';
import uiuxIcon from '../images/uiux.svg';
import aiIcon from '../images/ai.svg';
import seoIcon from '../images/seo.svg';
import marketingIcon from '../images/marketing.svg';
import cloudIcon from '../images/cloud.svg';
import supportIcon from '../images/support.svg';
import graphicIcon from '../images/graphic.svg';
import amazonIcon from '../images/amazon.svg';
import blockchainIcon from '../images/blockchain.svg';
import gameDevIcon from '../images/gamedev.svg';
import '../css/home.css'; // Ensure this file is clean of any styles related to ServicesComponent

// Service data
const services = [
  {
    iconPath: phoneIcon,
    shapeColor: "#008080",
    title: "Mobile App Development",
    description: "Android & iOS Apps"
  },
  {
    iconPath: webIcon,
    shapeColor: "#FF5722",
    title: "Web Development",
    description: "Responsive and engaging websites"
  },
  {
    iconPath: gameDevIcon,
    shapeColor: "#795548",
    title: "Game Development",
    description: "Interactive and immersive gaming experiences"
  },
  {
    iconPath: aiIcon,
    shapeColor: "#3F51B5",
    title: "AI & Machine Learning",
    description: "Advanced algorithms and intelligent systems"
  },
  {
    iconPath: amazonIcon,
    shapeColor: "#FF9900",
    title: "Amazon Web Services",
    description: "Scalable cloud computing services"
  },
  {
    iconPath: blockchainIcon,
    shapeColor: "#00BFFF",
    title: "Blockchain Solutions",
    description: "Secure and decentralized technologies"
  },
  {
    iconPath: uiuxIcon,
    shapeColor: "#4CAF50",
    title: "UI/UX Design",
    description: "User-centered design and experience"
  },
  {
    iconPath: graphicIcon,
    shapeColor: "#FF6347",
    title: "Graphic Design",
    description: "Creative and visually appealing designs"
  },
  {
    iconPath: seoIcon,
    shapeColor: "#FFC107",
    title: "SEO Optimization",
    description: "Improve your search engine rankings"
  },
  {
    iconPath: marketingIcon,
    shapeColor: "#009688",
    title: "Digital Marketing",
    description: "Effective online marketing strategies"
  },
  {
    iconPath: cloudIcon,
    shapeColor: "#E91E63",
    title: "Cloud Solutions",
    description: "Scalable and secure cloud services"
  },
  {
    iconPath: supportIcon,
    shapeColor: "#FF9800",
    title: "IT Support",
    description: "Reliable technical support and maintenance"
  },
];

// ServiceCard component
const ServiceCard = ({ iconPath, shapeColor, title, description }) => (
  <CustomCard
    iconPath={iconPath}
    shapeColor={shapeColor}
    title={title}
    description={description}
  />
);

// Main ServicesComponent
const ServicesComponent = () => {
  const aboutRef = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    const aboutObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
          aboutObserver.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    const gridObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
          gridObserver.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    if (aboutRef.current) {
      aboutObserver.observe(aboutRef.current);
    }

    if (gridRef.current) {
      gridRef.current.childNodes.forEach((node) => gridObserver.observe(node));
    }

    return () => {
      aboutObserver.disconnect();
      gridObserver.disconnect();
    };
  }, []);

  return (
    <section id="services" className="home-section">
      <div className="about-content" ref={aboutRef}>
        <h2>Our Services</h2>
        <p>
          Impact Minds is dedicated to enhancing the productivity and growth of your business through 'Smart Solutions' and comprehensive 'Digital Transformation' of your business processes. By leveraging the latest state-of-the-art technologies, we provide a range of services designed to bring innovation and life to your products. Our expertise ensures that your business stays ahead in the competitive market, driving efficiency and success. Partner with us to experience unparalleled growth and transformation.
        </p>
      </div>

      <div className="services-grid" ref={gridRef}>
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            iconPath={service.iconPath}
            shapeColor={service.shapeColor}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </section>
  );
};

export default ServicesComponent;
