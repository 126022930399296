import React from 'react';

const HeaderDescription = ({ heading, description, showComma = true }) => (
  <div className="intro-text">
    <h1>
      <span className="white">{heading.primary}</span> <span className="gold">{heading.secondary}</span>
      {showComma && ','}<br />
      <span className="gold">{heading.tertiary}</span>
    </h1>
    <p>{description}</p>
  </div>
);

export default HeaderDescription;