import React, { useEffect } from 'react';
import Header from './header'; // Assuming correct import path
import './css/about.css';
import useDocumentTitle from './js/use_documents';
import SEO from './seo';

const About = () => {
  useDocumentTitle("Impact Minds - About");

  const heading = {
    primary: '',
    secondary: 'About Us',
    tertiary: '',
  };

  const description = `At Impact Minds, we have assembled a team of top-tier experts and developers across multiple disciplines. Our team consists of seasoned professionals excelling in various fields.`;

  useEffect(() => {
    const sections = document.querySelectorAll('.about-content section');
    const options = {
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('appear');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <SEO
        title="Impact Minds - About Us"
        description="Learn more about Impact Minds, our mission, values, and the team dedicated to providing exceptional IT services."
        keywords="about Impact Minds, IT company, software development, team"
        url="https://impactmindstech.com/about"
        image="%PUBLIC_URL%/logo192.png"
      />
      <Header heading={heading} description={description} showComma={false} />
      <div className="about-content">
        <section>
          <h2>Who We Are</h2>
          <p>
            At Impact Minds, we have assembled a team of top-tier experts and developers across multiple disciplines. Our team consists of seasoned professionals excelling in various fields.
          </p>
        </section>
        <section>
          <h2>Our Unique Approach</h2>
          <p>
            What sets Impact Minds apart is our ability to provide comprehensive solutions all in one place. Whether you need back-end development, front-end design, marketing, SEO, UI/UX, or video editing, we have you covered. We handle every aspect related to your projects with expertise.
          </p>
        </section>
        <section>
          <h2>Commitment to Excellence</h2>
          <p>
            In essence, Impact Minds ensures your projects are managed from inception to completion, delivering highly professional, flawless, and reliable applications and websites. We regularly manage a wide array of international and local projects, ensuring quality and excellence every step of the way.
          </p>
        </section>
      </div>
    </div>
  );
};

export default About;
