import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ 
  title, 
  description, 
  keywords, 
  url, 
  image, 
  structuredData 
}) => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{title}</title>
    
    {/* General Meta Tags */}
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="author" content="Impact Minds Team" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href={url} />

    {/* Open Graph / Facebook */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Impact Minds" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />

    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />

    {/* Structured Data */}
    <script type="application/ld+json">
      {structuredData ? JSON.stringify(structuredData) : `
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "${title}",
        "description": "${description}",
        "url": "${url}",
        "sameAs": [
          "https://www.linkedin.com/company/impactminds0/"
        ],
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+92 327 6228976",
          "contactType": "customer service"
        },
        "image": {
          "@type": "ImageObject",
          "url": "${image}",
          "width": 1200,
          "height": 630
        }
      }
      `}
    </script>
  </Helmet>
);

// Define the expected prop types and mark them as required
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  structuredData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
};

export default SEO;
