import React, { useState } from 'react';
import '../css/workflow.css';
import workflowVideo from '../videos/workflow.mp4';
import { FaFileAlt, FaPaintBrush, FaHammer, FaCog, FaRocket, FaCommentDots, FaWrench } from 'react-icons/fa';

const steps = [
  {
    title: 'Requirements Gathering',
    description: 'We gather and analyze the requirements to understand your needs.',
    icon: <FaFileAlt />
  },
  {
    title: 'Design',
    description: 'We create a comprehensive design that meets your expectations.',
    icon: <FaPaintBrush />
  },
  {
    title: 'Construction',
    description: 'We build the project based on the approved design.',
    icon: <FaHammer />
  },
  {
    title: 'Testing',
    description: 'We rigorously test the project to ensure quality and performance.',
    icon: <FaCog />
  },
  {
    title: 'Deployment',
    description: 'We deploy the project to the live environment for your use.',
    icon: <FaRocket />
  },
  {
    title: 'Feedback',
    description: 'We gather feedback to continuously improve our service.',
    icon: <FaCommentDots />
  },
  {
    title: 'Maintenance',
    description: 'We provide ongoing support and maintenance to ensure smooth operation.',
    icon: <FaWrench />
  }
];

const Workflow = () => {
  const [expandedStep, setExpandedStep] = useState(null);

  const toggleExpand = (index) => {
    setExpandedStep(expandedStep === index ? null : index);
  };

  return (
    <section className="workflow-section">
      <h2>Our Workflow</h2>
      <p>We follow a professional workflow to provide you with top-notch service at reasonable costs.</p>
      <div className="workflow-content">
        <div className="video-container">
          <video src={workflowVideo} autoPlay loop muted playsInline className="workflow-video" />
        </div>
        <div className="workflow-steps">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`workflow-step ${expandedStep === index ? 'expanded' : ''}`}
              onClick={() => toggleExpand(index)}
            >
              <div className="workflow-step-icon">{step.icon}</div>
              <div>
                <h3>{step.title}</h3>
                {expandedStep === index && <p>{step.description}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Workflow;
