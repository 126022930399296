import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SEO from '../seo';
import '../css/BlogDetail.css';

const BlogDetail = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://impactminds.vercel.app/api/blog/getSingleBlog/${id}`, {
          method: 'GET',
          headers: {
            'accept': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch blog details');
        }

        const data = await response.json();
        setBlogData(data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
        toast.error('Failed to fetch blog details');
      }
    };

    if (id) {
      fetchBlog();
    }
  }, [id]);

  if (loading) {
    return (
      <div className="shimmer-wrapper">
        <div className="shimmer thumbnail"></div>
        <div className="shimmer title"></div>
        <div className="shimmer text"></div>
      </div>
    );
  }

  if (error) {
    return <div className="blog-detail-error">{error}</div>;
  }

  const formattedDate = new Date(blogData.dateTime).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": blogData.title || 'Sample Blog Title',
    "description": blogData.metaDescription ? blogData.metaDescription.substring(0, 160) : 'This is a sample blog description.',
    "image": {
      "@type": "ImageObject",
      "url": blogData.thumbnail || 'https://via.placeholder.com/1200x630',
      "width": 1200,
      "height": 630
    },
   
    "publisher": {
      "@type": "Organization",
      "name": "Impact Minds",
      "logo": {
        "@type": "ImageObject",
        "url": "https://via.placeholder.com/200x60" // Replace with actual logo URL if available
      }
    },
    "datePublished": blogData.dateTime || new Date().toISOString(),
    "dateModified": blogData.dateTime || new Date().toISOString()
  };

  return (
    <>
    <SEO
        title={blogData.title || 'Sample Blog Title'}
        description={blogData.metaDescription ? blogData.metaDescription.substring(0, 160) : 'This is a sample blog description.'}
        keywords={blogData.keywords || 'blog, sample, SEO'}
        url={window.location.href}
        image={blogData.thumbnail || 'https://via.placeholder.com/1200x630'}
        structuredData={articleStructuredData}
      />
      <ToastContainer />
      
      <div className="blog-detail-container">
        <div className="blog-detail-header">
          <img
            src={blogData.thumbnail || 'https://via.placeholder.com/1200x400'}
            alt={blogData.title}
            className="blog-detail-thumbnail"
          />
          <div className="blog-detail-meta">
            <h1 className="blog-detail-title">{blogData.title || 'Sample Blog Title'}</h1>
            <p className="blog-detail-author">Powered By Impact Minds | {formattedDate}</p>
          </div>
        </div>
        <div
          className="blog-detail-content"
          dangerouslySetInnerHTML={{ __html: blogData.content || '<p>This is a sample blog post. Add your content here.</p>' }}
        />
        <div className="blog-detail-sidebar">
          <div className="author-info">
            <h2>About us</h2>
            <p>Impact minds is a software development organization delivering premier services to businesses of all sizes, from startups to enterprises, ensuring they stay ahead with the latest technological advancements in a dynamic business environment. Our offerings include SEO marketing and a comprehensive range of services tailored to meet every need.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
