
import RecentProjects from './js/portfolio';
import './css/home.css';
import Workflow from './js/workflow';
import Testimonial from './js/testimonial';
import ServicesComponent from './js/service-components';
import useDocumentTitle from './js/use_documents';
import SEO from './seo';

const Home = () => {
  useDocumentTitle("Impact Minds - Home")

  return (
    <>
      <SEO
        title="Impact Minds"
        description="Impact Minds is a software development organization delivering premier services to businesses of all sizes, ensuring they stay ahead with the latest technological advancements."
        keywords="IT services, software development, web design, digital marketing, SEO marketing, Impact Minds"
        url="https://impactmindstech.com/"
        image="%PUBLIC_URL%/logo192.png"
      />
      <section id="home" className="home-section">
        <ServicesComponent />
        <Workflow />
        <Testimonial />
        <RecentProjects />
      </section>
    </>
  );
};

export default Home;
