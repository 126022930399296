import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import './css/header.css'; // Ensure this path is correct
import logo from './logo1.png'; // Ensure this path is correct
import ParticleAnimation from './particleanimation'; // Ensure this path is correct
import HeaderDescription from './description'; // Ensure this path is correct
import Home from './home'; // Ensure this path is correct
const Header = ({ heading, description, showComma = true,showHome=false ,contactInfo}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header-container">
        <div className="logo">
          <img src={logo} alt="Impact Minds Logo" />
        </div>
        <ParticleAnimation />
        <div className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <HeaderDescription heading={heading} description={description} showComma={showComma} contactInfo={contactInfo}/>
        
        <nav className={menuOpen ? 'open' : ''}>
          <ul>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/portfolio"
                className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
              >
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blog"
                className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      {showHome && <Home />}
    </>
  );
};

export default Header;
