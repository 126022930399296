// src/components/Cases.js
import React, { useEffect } from 'react';
import Header from '../header'; // Assuming correct import path
import '../css/cases.css';
import SEO from '../seo';
import learningImage from '../images/learning.jpg';
import jobImage from '../images/job.jpg';
import foodDelivery from '../images/delivery.png';
import eventBooking from '../images/eventbooking.jpg';
import taxiBooking from '../images/taxiBooking.png';
import ecommerceWeb from '../images/ecommerceweb.png';
import educationDashboard from '../images/educationDashboard.png';
import attacKGame from '../images/game.png';
import tradingApp from '../images/trading.png';
import PulsurAiForBusiness from '../images/pulsarAi.png';
import foodBarWeb from '../images/foodbar.png';
import creddyWeb from '../images/creddy.png';
import useDocumentTitle from './use_documents';

const Cases = () => {
  useDocumentTitle("Impact Minds - Portfolio");

  const heading = {
    primary: '',
    secondary: 'Portfolio Projects',
    tertiary: '',
  };

  const projects = [
    {
      id: 1,
      title: 'Food Delivery',
      description: 'Food delivery service app for Android and iOS.',
      longDescription: 'Impact Minds worked on this comprehensive food delivery service app, which offers users a seamless experience for ordering food from various restaurants. The app includes features like real-time tracking, multiple payment options, and a user-friendly interface.',
      thumbnail: foodDelivery,
      fullImage: foodDelivery,
    },
    {
      id: 2,
      title: 'Find Your Job',
      description: 'A job search application for Android and iOS devices.',
      longDescription: 'Impact Minds worked on this job search application, allowing users to find and apply for jobs in various fields. The app provides advanced search filters, resume building tools, and job alerts to keep users updated with the latest job opportunities.',
      thumbnail: jobImage,
      fullImage: jobImage,
    },
    {
      id: 3,
      title: 'Taxi Booking',
      description: 'Taxi booking application for Android.',
      longDescription: 'Impact Minds worked on this taxi booking application, which helps users book taxis easily. The app includes features like fare estimation, real-time driver tracking, and multiple payment methods to ensure a smooth and secure ride experience.',
      thumbnail: taxiBooking,
      fullImage: taxiBooking,
    },
    {
      id: 4,
      title: 'Event Booking',
      description: 'Platform for booking events, available on Android and iOS.',
      longDescription: 'Impact Minds worked on this event booking platform, allowing users to browse and book tickets for various events. The app offers a user-friendly interface, secure payment gateways, and personalized recommendations based on user preferences.',
      thumbnail: eventBooking,
      fullImage: eventBooking,
    },
    {
      id: 5,
      title: 'Pulsar AI for Business',
      description: 'AI-powered business solutions, available on multiple platforms.',
      longDescription: 'Impact Minds worked on Pulsar AI for Business, a platform providing AI-powered solutions for businesses. The platform includes features like predictive analytics, customer behavior insights, and automation tools to enhance business efficiency and decision-making.',
      thumbnail: PulsurAiForBusiness,
      fullImage: PulsurAiForBusiness,
    },
    {
      id: 6,
      title: 'Food Bar Web',
      description: 'Web application for food and beverage management.',
      longDescription: 'Impact Minds worked on this web application for food and beverage management, offering features like inventory tracking, sales analysis, and supplier management. The platform aims to streamline operations and improve efficiency for food and beverage businesses.',
      thumbnail: foodBarWeb,
      fullImage: foodBarWeb,
    },
    {
      id: 7,
      title: 'Creddy Web',
      description: 'Financial services web application for users and businesses.',
      longDescription: 'Impact Minds worked on Creddy Web, a financial services platform that provides various tools for personal finance management and business financial solutions. Features include budgeting tools, expense tracking, and financial goal setting.',
      thumbnail: creddyWeb,
      fullImage: creddyWeb,
    },
    {
      id: 8,
      title: 'Learning App',
      description: 'An educational app for Android and iOS platforms.',
      longDescription: 'Impact Minds worked on this educational app, designed to provide users with interactive learning experiences. The app includes video lessons, quizzes, and progress tracking to enhance the learning process.',
      thumbnail: learningImage,
      fullImage: learningImage,
    },
    {
      id: 9,
      title: 'E-commerce Web',
      description: 'Online shopping website with support for multiple devices.',
      longDescription: 'Impact Minds worked on this e-commerce website, offering a wide range of products and a seamless shopping experience. The platform includes features like product search, secure checkout, and order tracking to ensure customer satisfaction.',
      thumbnail: ecommerceWeb,
      fullImage: ecommerceWeb,
    },
    {
      id: 10,
      title: 'Education Dashboard',
      description: 'Dashboard for managing educational content, with support for multiple devices.',
      longDescription: 'Impact Minds worked on this education dashboard, designed to help educators manage and organize educational content effectively. Features include course management, student progress tracking, and interactive learning tools.',
      thumbnail: educationDashboard,
      fullImage: educationDashboard,
    },
    {
      id: 11,
      title: 'Attack Game',
      description: 'Exciting game for Android and iOS platforms.',
      longDescription: 'Impact Minds worked on this exciting game, offering users a thrilling gaming experience with challenging levels, immersive graphics, and engaging gameplay mechanics.',
      thumbnail: attacKGame,
      fullImage: attacKGame,
    },
    {
      id: 12,
      title: 'Trading App',
      description: 'Stock trading application for Android and iOS.',
      longDescription: 'Impact Minds worked on this stock trading application, providing users with real-time market data, trading tools, and portfolio management features to help them make informed trading decisions.',
      thumbnail: tradingApp,
      fullImage: tradingApp,
    },
  ];

  const description = `During the 3-years of work experience in the field made us work with some of the best business ideas. Some of our portfolio projects are showcased below.`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const seoData = {
    title: "Impact Minds - Portfolio Projects",
    description: "Showcasing the diverse portfolio of Impact Minds, including projects in mobile development, web development, AI solutions, and more.",
    keywords: ["portfolio", "projects", "mobile development", "web development", "AI solutions", "food delivery app", "job search app", "taxi booking app", "event booking app", "business solutions"],
    url: "https://impactmindstech.com/",
    image: "%PUBLIC_URL%/logo192.png"
  };

  return (
    <>
      <SEO {...seoData} />
      <div>
        <Header heading={heading} description={description} showComma={false} />
        <div className="projects-section">
          {projects.map((project) => (
            <div key={project.id} className="project-detail">
              <div className="project-detail-content">
                <h2 className="case-title">{project.title}</h2>
                <p>{project.longDescription}</p>
              </div>
              <div className="project-detail-image">
                <img src={project.fullImage} alt={project.title} />
              </div>
              <div className="divider"></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Cases;
