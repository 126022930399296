import React, { useEffect, useState } from 'react';
import '../css/Blog.css';
import Header from '../header';
import { useNavigate } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional effect for better UX

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const limit = 0; // Change this to control the number of blogs to fetch

  useEffect(() => {
    fetch(`https://impactminds.vercel.app/api/blog/getAllBlogs/${limit}`, {
      method: 'GET',
      headers: {
        'accept': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      if (data.statusCode === 200) {
        setBlogs(data.data);
      } else {
        console.error('Failed to fetch blogs');
      }
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    })
    .finally(() => {
      setLoading(false);
    });
  }, [limit]);

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '') // Remove invalid characters
      .trim() // Remove leading and trailing spaces
      .replace(/\s+/g, '-') // Replace spaces with single dash
      .replace(/-+/g, '-') // Replace multiple dashes with a single dash
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing dashes
  };

  return (
    <>
      <Header
        heading={{ primary: 'Welcome to the', secondary: 'Blog' }}
        description="Dive into the world of insights and updates with our blog. Here, we explore a wide range of topics, from the latest industry trends and innovations to in-depth analyses and thought-provoking discussions. Our blog is designed to keep you informed and engaged, providing valuable content that enhances your understanding of the evolving landscape of technology, business, and beyond. Whether you are a tech enthusiast, a business professional, or simply curious about the latest developments, our blog offers a rich resource of information tailored to your interests. Stay tuned for regular updates and feel free to reach out with your thoughts and feedback."
        showComma={false}
        showHome={false}
      />
      <div className="blog-container">
        {loading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="skeleton-card">
              <div className="skeleton-thumbnail"></div>
              <div className="skeleton-title"></div>
              <div className="skeleton-description"></div>
              <div className="skeleton-button"></div>
            </div>
          ))
        ) : (
          blogs.map(blog => (
            <div key={blog._id}
              className="blog-card"
              onClick={() => navigate(`/blog/${generateSlug(blog.title)}/${blog._id}`, { state: { blog } })}>
              {/* <LazyLoadImage
                src={blog.thumbnail}
                alt={blog.altImageText}
                className="blog-thumbnail"
                effect="blur" // Optional effect for better UX
              /> */}
              <img
  src={blog.thumbnail}
  alt={blog.altImageText}
  className={`blog-thumbnail`}
  loading="lazy" // Native lazy loading
  // onLoad={() => setLoading(false)}
  // onError={() => setLoading(false)} // Optionally handle errors
/>
              <h2 className="blog-title">{blog.title}</h2>
              <p className="meta-description">{blog.metaDescription}</p>
             
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Blog;
