import React, { useState } from 'react';
import Header from '../header';
import '../css/contact.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import useDocumentTitle from './use_documents';
import { sendEmail } from '../api/contactapi';
import SEO from '../seo';
const Contact = () => {
  useDocumentTitle("Impact Minds - Contact");

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    try {
      await sendEmail(formData.name, formData.email, formData.message);
      setStatus('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setStatus('Failed to send message.');
    }
  };
  const seoProps = {
    title: "Impact Minds",
    description: "Get in touch with Impact Minds. We'd love to hear from you! Reach out to us through our contact form or directly via email and phone.",
    keywords: "Contact, Impact Minds, Email, Phone, Message, Customer Support, Inquiry, Reach Us, Get in Touch, Business Communication",
    url: "https://impactmindstech.com/",
    image: "%PUBLIC_URL%/logo192.png" // Replace with your image URL
  };
  

  return (
    <section id="contact" className="contact-section">
      <SEO {...seoProps} />
      <Header 
        heading={{ primary: 'Get in Touch', secondary: 'With Us', tertiary: 'Today' }}
        description="We'd love to hear from you! Reach out to us through any of the following methods."
      />
      <div className="contact-data">
        <div className="contact-information">
          <div className="information-item">
            <FaMapMarkerAlt className="information-icon" />
            <p className="information-text">
              <a href="https://www.google.com/maps/place/Kohinoor+Plaza+I/@31.4126038,73.1151958,17z/data=!3m1!4b1!4m6!3m5!1s0x392268725b330195:0x41252daea86b04b7!8m2!3d31.4126038!4d73.1151958!16zL20vMGZmcHM0?entry=ttu" target="_blank" rel="noopener noreferrer">
                Kohinoor Plaza, Faisalabad, Pakistan
              </a>
            </p>
          </div>
          <div className="information-item">
            <FaPhone className="information-icon" />
            <p className="information-text">
              <a href="tel:+923276228976">
                +92 327 6228976
              </a>
            </p>
          </div>
          <div className="information-item">
            <FaEnvelope className="information-icon" />
            <p className="information-text">
              <a href="mailto:impact.minds0@gmail.com">
                impact.minds0@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit" className="submit-button">Send Message</button>
            {status && <p className="form-status">{status}</p>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
