import React from 'react';
import styled from 'styled-components';

// Card Container
const CardContainer = styled.div`
  width: 100%; /* Ensure it takes full width in its container */
  max-width: 300px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow for more elevation */
  overflow: hidden;
  position: relative; /* Needed for ripple positioning */
  transition: transform 1s, background-color 1s; /* Smooth background color transition */

  &:hover {
    background-color: ${(props) => props.shapeColor || '#008080'}; /* Change background color to shapeColor */
  }

  &:hover::before {
    transform: scale(4); /* Adjust scale for ripple size */
    opacity: 0; /* Hide the ripple after animation */
  }

  /* Ripple animation styling */
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px; /* Adjust width for ripple size */
    height: 100px; /* Adjust height for ripple size */
    background-color: ${(props) => props.shapeColor || '#008080'};
    border-radius: 50%;
    transform: scale(0);
    opacity: 0.6;
    transition: transform 1s, opacity 1s; /* Smooth animation */
    pointer-events: none; /* Prevent interference with click events */
    z-index: 0; /* Ensure ripple is behind content */
    /* Center the ripple */
    transform-origin: center;
    top: 50%;
    left: 50%;
    margin-left: -50px; /* Half of the width */
    margin-top: -50px; /* Half of the height */
  }
    @media (max-width: 800px) {
    max-width: 430px; /* Set max-width to 350px when screen width is 800px or less */
  }
`;

// Shape Component
const Shape = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${(props) => props.shapeColor || '#008080'};
  clip-path: circle(50% at 50% 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s; /* Smooth background color transition */
`;

// Icon Component
const Icon = styled.img`
  width: 30px;
  height: 30px;
  transition: filter 1s; /* Smooth color transition */

  ${CardContainer}:hover & {
    filter: invert(1); /* Inverts colors to white on hover */
  }
`;

// Title Component
const Title = styled.h3`
  margin: 10px 0 5px 0;
  font-size: 18px;
  color: #333;
  transition: color 2s; /* Smooth color transition */

  ${CardContainer}:hover & {
    color: #fff; /* Change text color to white on hover */
  }
`;

// Description Component
const Description = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
  text-align: center;
  transition: color 2s; /* Smooth color transition */

  ${CardContainer}:hover & {
    color: #fff; /* Change text color to white on hover */
  }
`;

// CustomCard Component
const CustomCard = ({ iconPath, shapeColor, title, description }) => (
  <CardContainer shapeColor={shapeColor}>
    <Shape shapeColor={shapeColor}>
      <Icon src={iconPath} alt="Icon" />
    </Shape>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </CardContainer>
);

export default CustomCard;
