import React, { useEffect, useRef, useState } from 'react';
import '../css/testimonial.css';
import client from '../images/avatar.svg'; // Default image in case of errors

const Testimonial = () => {
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const loaderRef = useRef(null);
  const sliderRef = useRef(null);
  const slideIntervalRef = useRef(null);

  useEffect(() => {
    fetchReviews(); // Fetch initial reviews

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore && !loading) {
          fetchReviews();
        }
      },
      { threshold: 1.0 }
    );

    const currentLoaderRef = loaderRef.current;

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loading, hasMore]);

  useEffect(() => {
    const startAutoSlide = () => {
      slideIntervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      }, 3000);
    };

    const stopAutoSlide = () => {
      clearInterval(slideIntervalRef.current);
    };

    startAutoSlide();

    return () => {
      stopAutoSlide();
    };
  }, [reviews]);

  const fetchReviews = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://impactminds.vercel.app/api/review/getReview/10'); // Fetch 10 reviews at a time
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const { data } = await response.json();

      if (data.length === 0) {
        setHasMore(false);
      }
      setReviews((prevReviews) => [...prevReviews, ...data]);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseEnter = () => {
    clearInterval(slideIntervalRef.current);
  };

  const handleMouseLeave = () => {
    slideIntervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 3000);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % reviews.length);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 1 + reviews.length) % reviews.length);
  };

  return (
    <section className="testimonial-section">
      <h2>What Our Clients Say About Impact Minds</h2>
      <div
        className="testimonial-slider"
        ref={sliderRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {reviews.length === 0 && !loading ? (
          <p>No reviews found</p>
        ) : (
          <>
            <button className="nav-btn prev" onClick={handlePrev}>&#10094;</button>
            <div className="testimonial-slides" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
              {reviews.map((testimonial) => (
                <div key={testimonial._id} className="testimonial-card">
                  <img src={client} alt={testimonial.name} className="client-image" />
                  <h3>{testimonial.name}</h3>
                  <p className="client-role">{testimonial.role}, {testimonial.companyName}</p>
                  <p className="client-quote">"{testimonial.message}"</p>
                </div>
              ))}
            </div>
            <button className="nav-btn next" onClick={handleNext}>&#10095;</button>
          </>
        )}
        {loading && <div className="loading-spinner"></div>}
        {error && <p className="error-message">{error}</p>}
      </div>
      <div ref={loaderRef} />
    </section>
  );
  
};

export default Testimonial;
