import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Cases from './components/js/cases';
import About from './components/about-us';
import Services from './components/js/services';
import Contact from './components/js/contact';
import Blog from './components/js/blog';
import AddReview from './components/js/add_review';
import BlogDetail from './components/js/BlogDetail';
const heading = {
  primary: 'Merging',
  secondary: 'Innovation',
  tertiary: 'Design and Technology',
};

const description = 'Impact minds is a software development organization delivering premier services to businesses of all sizes, from startups to enterprises, ensuring they stay ahead with the latest technological advancements in a dynamic business environment. Our offerings include SEO marketing and a comprehensive range of services tailored to meet every need.';

const App = () => (
  <Router>
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
         
             
              <Header heading={heading} description={description} showHome={true} />
            
          }
        />
        <Route
          path="/about"
          element={
          
              
              <About />
            
          }
        />
        <Route
          path="/services"
          element={
            
             
              <Services />
            
          }
        />
        <Route
          path="/portfolio"
          element={
          
             
              <Cases />
           
          }
        />
         <Route
          path="/blog"
          element={
           
              
              <Blog />
            
          }
        />
        <Route path="/blog/:slug/:id" element={<BlogDetail />} />
        <Route
          path="/contact"
          element={
            
              <Contact />
         
            
          }
        />
       
         <Route
          path="/addReviewToThecompanyWeWillBeThankful"
          element={
            <>
            
              <AddReview />
            </>
            
          }
        />
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;
